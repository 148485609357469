<template>
  <div class="h-screen" style="direction: ltr">
    <div @click="handleCloseCarousel" class="close-carousel">
      <img src="../assets/images/icons/close.svg" alt="">
    </div>
    <Carousel class="justify-center h-full " perPage="1">
      <Slide v-for="(img ,i) in media" :key="i">
        <img :src="baseURL + img.link" alt="">
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';

export default {
  name: "carousel",
  components: {
    Carousel,
    Slide
  },
  props: {
    media : {
      type : Array,
      required : true
    }
  },
  data(){
    return{
      baseURL : this.$store.state.general.baseURL
    }
  },
  methods:{
    handleCloseCarousel(){
      this.$emit('close-carousel' , false)
    }
  }
}
</script>

<style scoped>
.close-carousel{
  cursor: pointer;
  width: 40px;
  position: absolute;
  right: 21px;
  top: 20px;
  z-index: 100;
}
</style>